@import '@aurora/shared-client/styles/_variables.pcss';
@import '@aurora/shared-client/styles/mixins/_focus.pcss';

.lia-nav {
  position: relative;
  margin: 10px auto 25px -10px;
  min-height: 44px;
  flex-wrap: nowrap;

  &.lia-has-border:after {
    position: absolute;
    content: '';
    left: 10px;
    right: 0;
    bottom: 0;
    height: 1px;
    background-color: var(--lia-bs-border-color);
  }
}

.lia-nav-btn {
  display: flex;
  align-items: center;
  position: relative;
  font-weight: var(--lia-font-weight-md);
  font-size: var(--lia-bs-font-size-sm);
  line-height: var(--lia-bs-line-height-base);
  background-color: transparent;
  border: 0;
  padding: 8px 10px 15px;
  color: var(--lia-bs-body-color);
  white-space: nowrap;
  transition: color var(--lia-timing-normal);

  &.lia-nav-dropdown[aria-expanded='true']:after {
    background-color: var(--lia-bs-link-color);
  }
  &:not(.lia-is-borderless):before {
    display: block;
    position: absolute;
    content: '';
    bottom: 0;
    opacity: 0;
    width: calc(100% - 20px);
    border-bottom: 2px solid var(--lia-bs-link-color);
    transition: opacity var(--lia-timing-normal);
  }
  &.lia-is-active {
    color: var(--lia-bs-link-color);

    &:not(.lia-is-borderless):before {
      opacity: 1;
    }
    &.lia-nav-dropdown {
      &:after {
        background-color: var(--lia-bs-link-color);
      }
      .lia-icon {
        fill: currentColor;
      }
    }
  }
  &.lia-is-hidden {
    display: none;
  }
  &:hover,
  &:focus {
    color: var(--lia-bs-link-color);
    box-shadow: unset;

    .lia-icon {
      fill: currentColor;
    }
  }
  &:focus-visible {
    @include default-outline();
    outline-offset: -3px;
  }
}

.lia-nav-dropdown {
  height: 100%;
}

.lia-icon {
  margin-left: 3px;
}

.lia-more {
  min-width: max-content;
}

.lia-nav-dropdown-menu .lia-dropdown-item:active {
  outline: none;
  box-shadow: none;
}

.lia-items {
  display: flex;
  overflow-x: hidden;
}
